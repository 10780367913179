/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
// src/components/IncomingVessels.jsx

import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { ShipDataContext } from './ShipDataContext';
import { portBerths } from '../data/portData';

const IncomingVessels = ({ selectedPort, setHighlightedShipId }) => {
    const { ships, assignBerthToShip, portBerthsStatus, solverMode } = useContext(ShipDataContext);

    const incomingShips = ships.filter(
        (ship) =>
            ship.destinationPort === selectedPort &&
            !ship.isDocked &&
            (ship.waitingAtPort || !ship.waitingAtPort)
    );

    const handleMouseEnter = (shipId) => {
        setHighlightedShipId(shipId);
    };

    const handleMouseLeave = () => {
        setHighlightedShipId(null);
    };

    const handleAssignBerth = async (ship) => {
        const availableBerths = portBerths[selectedPort]
            .map((berth, index) => ({
                name: berth.name,
                index,
                isOccupied: portBerthsStatus[selectedPort][index] !== null,
            }))
            .filter((berth) => !berth.isOccupied);

        if (availableBerths.length === 0) {
            Swal.fire("No available berths at this port.");
            return;
        }

        const inputOptions = availableBerths.reduce((options, berth) => {
            options[berth.index] = berth.name;
            return options;
        }, {});

        const { value: selectedBerthIndex } = await Swal.fire({
            title: `Assign Berth to Ship ${ship.id}`,
            input: "select",
            inputOptions,
            inputPlaceholder: "Select a berth",
            showCancelButton: true,
            inputValidator: (value) => {
                return value ? null : "You need to select a berth!";
            },
        });

        if (selectedBerthIndex !== undefined) {
            assignBerthToShip(ship.id, parseInt(selectedBerthIndex, 10), selectedPort);
            Swal.fire(`Assigned to ${availableBerths.find(b => b.index === parseInt(selectedBerthIndex, 10)).name}`);
        }
    };

    return (
        <div className="collapsible-card">
            <h3>Incoming Vessels</h3>
            <table className="ships-table">
                <thead>
                    <tr>
                        <th>Ship</th>
                        <th>Type</th>
                        <th>Cargo</th>
                        <th>Value</th>
                        <th>Status</th>
                        {solverMode === 'Manual' && <th>Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {incomingShips.map((ship) => (
                        <tr
                            key={ship.id}
                            onMouseEnter={() => handleMouseEnter(ship.id)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <td>{ship.id}</td>
                            <td title={ship.metadata.type}>
                                {ship.metadata.type.length > 10
                                    ? `${ship.metadata.type.slice(0, 10)}...`
                                    : ship.metadata.type}
                            </td>
                            <td title={ship.metadata.commodity || ship.metadata.type}>
                                {(ship.metadata.commodity || ship.metadata.type).length > 10
                                    ? `${(ship.metadata.commodity || ship.metadata.type).slice(0, 10)}...`
                                    : ship.metadata.commodity || ship.metadata.type}
                            </td>
                            <td>${ship.metadata.cargoValue}</td>
                            <td>
                                <span className={`status ${ship.waitingAtPort ? 'waiting' : 'on-move'}`}>
                                    {ship.waitingAtPort ? 'Waiting' : 'On Move'}
                                </span>
                            </td>
                            {solverMode === 'Manual' && (
                                <td>
                                    {ship.waitingAtPort && (
                                        <button onClick={() => handleAssignBerth(ship)}>Assign Berth</button>
                                    )}
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default IncomingVessels;