/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// src/components/PortSelector.jsx

import React from 'react';
import PropTypes from 'prop-types';

const PortSelector = ({ onSelectPort }) => {
    const ports = ["New York", "London", "Tokyo", "Sydney", "Dubai", "Mundra"];

    const handleSelectPort = (port) => {
        if (typeof onSelectPort === 'function') {
            onSelectPort(port);
        } else {
            console.error("onSelectPort is not a function.");
        }
    };

    return (
        <div className="port-selector">
            <h4>Select Port</h4>
            <div className="port-list">
                {ports.map(port => (
                    <button
                        key={port}
                        className="port-button"
                        onClick={() => handleSelectPort(port)}
                    >
                        {port}
                    </button>
                ))}
            </div>
            <style>{`
                .port-selector {
                    margin-bottom: 20px;
                }
                .port-list {
                    display: flex;
                    gap: 10px;
                }
                .port-button {
                    padding: 10px 15px;
                    background-color: #28a745;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }
                .port-button:hover {
                    background-color: #218838;
                }
            `}</style>
        </div>
    );
};

PortSelector.propTypes = {
    onSelectPort: PropTypes.func.isRequired,
};

export default PortSelector;
