/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useContext } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import FlyToView from "./FlyToView";
import TracerToggle from "./TracerToggle";
import SolverOverlay from "./SolverOverlay";
import BerthsLayer from "./BerthsLayer";
import ShipsLayer from "./ShipsLayer";
import SeaRoutesLayer from "./SeaRoutesLayer";
import BerthAssignmentMenu from "./BerthAssignmentMenu";
import { portCoordinates, seaRoutes, portBerths } from "../data/portData";
import { ShipDataContext } from "./ShipDataContext";
import CraneModelLayer from "./CraneModelLayer";
import TruckModelLayer from "./TruckModelLayer";
import CargoContainerLayer from "./CargoContainerLayer"; // Now included

const PortMap = ({
    selectedPort,
    showAllBerths,
    highlightedBerth,
    highlightedShipId,
}) => {
    const [tracerEnabled, setTracerEnabled] = useState(false);

    const {
        ships,
        portBerthsStatus,
        solverMode,
        handleSolverModeChange,
        setPortBerthsStatus,
        assignBerthToShip,
    } = useContext(ShipDataContext);

    const mapRef = useRef(null);

    const handleToggleTracer = () => {
        setTracerEnabled((prev) => !prev);
    };

    const center = selectedPort ? portCoordinates[selectedPort] : [0, 0];

    return (
        <div className="port-map-container">
            <div className="map-controls">
                <TracerToggle enabled={tracerEnabled} onToggle={handleToggleTracer} />
            </div>
            <MapContainer
                center={center}
                zoom={10}
                whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
                style={{
                    height: "100%",
                    width: "100%",
                }}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {selectedPort && <FlyToView center={center} zoom={10} />}
                <BerthsLayer
                    portBerths={portBerths}
                    selectedPort={selectedPort}
                    showAllBerths={showAllBerths}
                    highlightedBerth={highlightedBerth}
                />
                <ShipsLayer ships={ships} highlightedShipId={highlightedShipId} />
                <SeaRoutesLayer tracerEnabled={tracerEnabled} seaRoutes={seaRoutes} />

                <CraneModelLayer />
                <TruckModelLayer />
                <CargoContainerLayer /> {/* Containers area */}
            </MapContainer>

            <div className="solver-overlay-container">
                <SolverOverlay
                    numberOfWaitingShips={
                        ships.filter((ship) => ship.waitingAtPort && ship.currentPort === selectedPort)
                            .length
                    }
                    numberOfOccupiedBerths={
                        portBerthsStatus[selectedPort]?.filter((berth) => berth !== null).length ?? 0
                    }
                    solverMode={solverMode}
                    setSolverMode={handleSolverModeChange}
                    metrics={{}}
                    onAISolve={() => handleSolverModeChange("AISolver")}
                />
            </div>
        </div>
    );
};

export default PortMap;
