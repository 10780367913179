/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// src/components/ShipDataContext.jsx

import React, { createContext, useState, useEffect, useRef } from 'react';
import Ship from './Ship';
import { portCoordinates, seaRoutes, portBerths } from '../data/portData';

export const ShipDataContext = createContext();

export const ShipDataProvider = ({ children, shipCount }) => {
    const [ships, setShips] = useState([]);
    const [solverMode, setSolverMode] = useState('Manual');
    const [portBerthsStatus, setPortBerthsStatus] = useState({});
    const animationFrameId = useRef(null);
    const shipsRef = useRef([]);

    // Initialize berths status
    useEffect(() => {
        const status = {};
        Object.keys(portBerths).forEach((port) => {
            status[port] = portBerths[port].map(() => null);
        });
        setPortBerthsStatus(status);
    }, []);

    // Initialize ships
    useEffect(() => {
        const initializeShips = () => {
            const ports = Object.keys(portCoordinates);
            return Array.from({ length: shipCount }, (_, id) => {
                const startPort = ports[Math.floor(Math.random() * ports.length)];
                const ship = new Ship(id + 1, startPort, portCoordinates, seaRoutes);
                const nextPort = getRandomDestination(startPort);
                ship.setDestination(nextPort);
                ship.dockingProgress = 0; // Add docking progress tracking
                return ship;
            });
        };

        const newShips = initializeShips();
        setShips(newShips);
        shipsRef.current = newShips;
    }, [shipCount]);

    // Function to get a random destination
    const getRandomDestination = (currentPort) => {
        const availableDestinations = new Set();
        Object.keys(seaRoutes).forEach((routeKey) => {
            const [fromPort, toPort] = routeKey.split('-');
            if (fromPort === currentPort) {
                availableDestinations.add(toPort);
            } else if (toPort === currentPort) {
                availableDestinations.add(fromPort);
            }
        });

        const destinationsArray = Array.from(availableDestinations);
        return destinationsArray.length > 0
            ? destinationsArray[Math.floor(Math.random() * destinationsArray.length)]
            : currentPort;
    };

    // Move ships function with error handling
    const moveShips = () => {
        try {
            shipsRef.current = shipsRef.current.map((ship) => {
                if (ship.isDocked) {
                    // Ship is docked, check if docking time is over
                    if (Date.now() - ship.dockingStartTime >= 60000) {
                        undockShip(ship);
                    }
                } else if (ship.isMovingToBerth) {
                    // Ship is moving to berth
                    const berthPosition = getBerthPosition(ship.currentPort, ship.assignedBerthIndex);
                    if (berthPosition && typeof ship.updatePositionTowards === 'function') {
                        ship.updatePositionTowards(berthPosition);
                        const distance = getDistance(ship.position, berthPosition);
                        if (distance && distance < 0.0005) {
                            // Ship has reached the berth
                            ship.position = berthPosition;
                            ship.isMovingToBerth = false;
                            ship.isDocked = true;
                            ship.dockingStartTime = Date.now();
                            startDockingProgress(ship);
                        }
                    } else {
                        console.error("Error: Berth position is missing or 'updatePositionTowards' is undefined.");
                    }
                } else if (ship.waitingAtPort) {
                    if (solverMode === 'AISolver') {
                        if (!ship.waitingTime) ship.waitingTime = 0;
                        ship.waitingTime += 1;
                        if (ship.waitingTime >= 5) {
                            autoAssignBerth(ship);
                            ship.waitingTime = 0;
                        }
                    }
                } else {
                    ship.updatePosition();
                    const destinationPosition = portCoordinates[ship.destinationPort];
                    const distance = destinationPosition ? getDistance(ship.position, destinationPosition) : null;
                    if (distance && distance < 0.01) {
                        ship.position = destinationPosition;
                        ship.waitingAtPort = true;
                        ship.currentPort = ship.destinationPort;
                    }
                }
                return ship;
            });

            setShips([...shipsRef.current]);
            animationFrameId.current = requestAnimationFrame(moveShips);
        } catch (error) {
            console.error('Error in moveShips:', error);
        }
    };

    useEffect(() => {
        if (shipsRef.current.length > 0) {
            moveShips();
        }
        return () => cancelAnimationFrame(animationFrameId.current);
    }, [solverMode]);

    const handleSolverModeChange = (mode) => {
        setSolverMode(mode);
    };

    const getDistance = (pos1, pos2) => {
        if (!pos1 || !pos2 || pos1.length < 2 || pos2.length < 2) return null;
        const [lat1, lon1] = pos1;
        const [lat2, lon2] = pos2;
        const deltaLat = lat2 - lat1;
        const deltaLon = lon2 - lon1;
        return Math.sqrt(deltaLat * deltaLat + deltaLon * deltaLon);
    };

    const getBerthPosition = (port, berthIndex) => {
        const berth = portBerths[port]?.[berthIndex];
        return berth?.coordinates?.[0] || null;
    };

    const assignBerthToShip = (shipId, berthIndex, port) => {
        const portStatus = portBerthsStatus[port];
        const berthPosition = getBerthPosition(port, berthIndex);

        if (!portStatus || !berthPosition) {
            console.error('Invalid berth coordinates or port status.');
            return;
        }

        setPortBerthsStatus((prevStatus) => {
            const updatedStatus = { ...prevStatus };
            updatedStatus[port] = [...updatedStatus[port]];
            updatedStatus[port][berthIndex] = shipId;
            return updatedStatus;
        });

        setShips((prevShips) => {
            const updatedShips = prevShips.map((ship) => {
                if (ship.id === shipId) {
                    ship.assignedBerthIndex = berthIndex;
                    ship.isMovingToBerth = true;
                    ship.waitingAtPort = false;
                }
                return ship;
            });
            shipsRef.current = updatedShips;
            return updatedShips;
        });
    };

    const undockShip = (ship) => {
        const port = ship.currentPort;
        const berthIndex = ship.assignedBerthIndex;
        if (!portBerthsStatus[port]) return;

        setPortBerthsStatus((prevStatus) => {
            const updatedStatus = { ...prevStatus };
            updatedStatus[port] = [...updatedStatus[port]];
            updatedStatus[port][berthIndex] = null;
            return updatedStatus;
        });

        const nextPort = getRandomDestination(port);
        ship.setDestination(nextPort);
        ship.currentPort = port;
        ship.isDocked = false;
        ship.dockingStartTime = null;
        ship.assignedBerthIndex = null;
        ship.dockingProgress = 0;
    };

    const autoAssignBerth = (ship) => {
        const port = ship.currentPort;
        const berths = portBerthsStatus[port];
        if (!berths) return;

        const availableBerthIndex = berths.findIndex((berth) => berth === null);
        if (availableBerthIndex !== -1) {
            assignBerthToShip(ship.id, availableBerthIndex, port);
        }
    };

    const startDockingProgress = (ship) => {
        ship.dockingProgress = 0;
        const interval = setInterval(() => {
            if (ship.dockingProgress >= 100) {
                clearInterval(interval);
                undockShip(ship);
            } else {
                ship.dockingProgress += 10;
                setShips([...shipsRef.current]);
            }
        }, 6000); // Adjusted for 60 seconds docking duration
    };

    return (
        <ShipDataContext.Provider
            value={{
                ships,
                setShips,
                portBerthsStatus,
                setPortBerthsStatus,
                solverMode,
                handleSolverModeChange,
                assignBerthToShip,
            }}
        >
            {children}
        </ShipDataContext.Provider>
    );
};