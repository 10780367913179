/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from "react";
import { useMap } from "react-leaflet";
import { Canvas, useFrame } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import * as THREE from "three";
import { truckRoutes } from "../data/truckRoutes"; // Ensure correct import

// Truck real-world dimensions (in meters)
const TRUCK_LENGTH_M = 180;
const TRUCK_WIDTH_M = 40;
const TRUCK_HEIGHT_M = 50;

const trucksConfig = [
    { color: "black", speed: 0.0001, routeIndex: 0 },
    { color: "blue", speed: 0.00008, routeIndex: 1 },
    { color: "purple", speed: 0.00009, routeIndex: 2 },
];

const MODEL_PATH = "/assets/truck.glb";

function Truck({ map, color, speed, routeIndex }) {
    const truckRef = useRef();
    const loader = new GLTFLoader();
    const [modelBox, setModelBox] = useState(null);
    const [t, setT] = useState(0); // Movement parameter along route
    const route = truckRoutes[routeIndex]?.coordinates;

    useEffect(() => {
        loader.load(
            MODEL_PATH,
            (gltf) => {
                console.log("Truck GLTF Loaded Successfully:", gltf);
                const truck = gltf.scene;

                //// Initial orientation
                //truck.rotation.set(THREE.MathUtils.degToRad(-90), THREE.MathUtils.degToRad(90), THREE.MathUtils.degToRad(180));
                // Initial orientation: two consecutive rotations
                truck.rotation.x = THREE.MathUtils.degToRad(90);  // First rotate -90� around z-axis
                truck.rotation.y = THREE.MathUtils.degToRad(-90); // Then rotate 90� around x-axis
                truck.rotation.z = THREE.MathUtils.degToRad(180);

                truck.traverse((child) => {
                    if (child.isMesh) {
                        child.material = new THREE.MeshStandardMaterial({
                            color: color,
                            roughness: 0.5,
                            metalness: 0.5,
                        });
                    }
                });

                truckRef.current.add(truck);

                const box = new THREE.Box3().setFromObject(truck);
                const size = new THREE.Vector3();
                box.getSize(size);
                setModelBox(size);
            },
            undefined,
            (error) => console.error("Error loading Truck GLB model:", error)
        );
    }, [color]);

    useFrame(({ camera }) => {
        if (!map || !truckRef.current || !modelBox || !route || route.length < 2) return;

        const size = map.getSize();
        camera.left = 0;
        camera.right = size.x;
        camera.bottom = 0;
        camera.top = size.y;
        camera.updateProjectionMatrix();

        // Update position along route
        const segmentCount = route.length - 1;
        let segmentIndex = Math.floor(t * segmentCount);
        if (segmentIndex >= segmentCount) segmentIndex = segmentCount - 1;
        const segmentT = (t * segmentCount) - segmentIndex;

        const startCoord = route[segmentIndex];
        const endCoord = route[segmentIndex + 1] || route[segmentCount];
        const lat = startCoord[0] + segmentT * (endCoord[0] - startCoord[0]);
        const lng = startCoord[1] + segmentT * (endCoord[1] - startCoord[1]);

        // Convert lat/lng to container point
        const containerPoint = map.latLngToContainerPoint([lat, lng]);
        const threeX = containerPoint.x;
        const threeY = size.y - containerPoint.y;

        truckRef.current.position.set(threeX, threeY, 0);

        // Scale truck for real-world size
        const offsetLat = lat + 0.0001;
        const p1 = map.latLngToContainerPoint([lat, lng]);
        const p2 = map.latLngToContainerPoint([offsetLat, lng]);
        const pixelDiff = p2.y - p1.y;
        const meterDiff = map.distance([lat, lng], [offsetLat, lng]);
        const pixelsPerMeter = Math.abs(pixelDiff / meterDiff);

        const longestModelDim = Math.max(modelBox.x, modelBox.y, modelBox.z);
        const desiredPixelLength = TRUCK_LENGTH_M * pixelsPerMeter;
        const scaleFactor = desiredPixelLength / longestModelDim;

        truckRef.current.scale.set(scaleFactor, scaleFactor, scaleFactor);

        // Adjust rotation to face direction of travel
        const dx = endCoord[1] - startCoord[1];
        const dy = endCoord[0] - startCoord[0];
        const angle = Math.atan2(dy, dx);
        truckRef.current.rotation.z = -angle;

        // Update t for next frame
        setT((prevT) => (prevT + speed) % 1);
    });

    return <group ref={truckRef} />;
}

export default function TruckModelLayer() {
    const map = useMap();

    return (
        <Canvas
            orthographic
            camera={{
                left: 0,
                right: window.innerWidth,
                top: window.innerHeight,
                bottom: 0,
                near: 0.1,
                far: 2000,
                position: [0, 0, 100],
            }}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                pointerEvents: "none",
                width: "100%",
                height: "100%",
                zIndex: 1000,
            }}
        >
            <ambientLight intensity={1} />
            <directionalLight position={[10, 10, 10]} intensity={1} />
            {map &&
                trucksConfig.map((cfg, i) => (
                    <Truck
                        key={i}
                        map={map}
                        color={cfg.color}
                        speed={cfg.speed}
                        routeIndex={cfg.routeIndex}
                    />
                ))}
        </Canvas>
    );
}
