/* eslint-disable no-unused-vars */
// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './components/SignIn';
import Dashboard from './components/Dashboard';
import LandingPage from './components/LandingPage'; // Import LandingPage

const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route
            path="/dashboard"
            element={localStorage.getItem('token') !== null ? <Dashboard /> : <Navigate to="/signin" />}
        />
    </Routes>
);

const App = () => {
    return (
        <Router>
            <AppRoutes />
        </Router>
    );
};

export default App;
export { AppRoutes }; // Export routes for sitemap generation
