// src/components/Ship.js

import { portCountries } from '../data/portData';
import { calculateNauticalMiles } from '../utils/nautical';
import { generateGreatCirclePath } from '../utils/greatCircle';
import { US, GB, IN, AU, JP, AE } from 'country-flag-icons/react/3x2'; // Import specific flags

const shipTypeConfigs = {
    Oil: { lengthRange: [290, 310], depthRange: [18, 22], cargoCapacityRange: [95000, 105000], penaltyRate: 2000, businessValueRange: [4800000, 5200000] },
    Coal: { lengthRange: [240, 260], depthRange: [17, 19], cargoCapacityRange: [75000, 85000], penaltyRate: 1500, businessValueRange: [2800000, 3200000] },
    Minerals: { lengthRange: [190, 210], depthRange: [14, 16], cargoCapacityRange: [45000, 55000], penaltyRate: 1000, businessValueRange: [2300000, 2700000] },
    Chemicals: { lengthRange: [140, 160], depthRange: [11, 13], cargoCapacityRange: [38000, 42000], penaltyRate: 1800, businessValueRange: [3300000, 3700000] },
    Cargo: { lengthRange: [90, 110], depthRange: [9, 11], cargoCapacityRange: [18000, 22000], penaltyRate: 800, businessValueRange: [1400000, 1600000] }
};

const generalCargoTypes = ['Textiles', 'Perishables', 'Electronics', 'Automobiles', 'Furniture'];

function getRandomInRange([min, max]) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const flagComponents = {
    US: US,
    GB: GB,
    IN: IN,
    AU: AU,
    JP: JP,
    AE: AE,
};

class Ship {
    constructor(id, startPort, portCoordinates, seaRoutes, shipType = null) {
        this.id = id;
        this.currentPort = startPort;
        this.position = portCoordinates[startPort] || null;
        this.isDocked = false;
        this.waitingAtPort = true;
        this.dockingStartTime = null;
        this.path = [];
        this.startTime = null;
        this.duration = 3000;
        this.portCoordinates = portCoordinates;
        this.seaRoutes = seaRoutes;
        this.metadata = this.generateShipMetadata(shipType || this.assignRandomShipType());
        this.metadata.source = this.currentPort;
    }

    assignRandomShipType() {
        const random = Math.random() * 100;
        return random < 60 ? 'Cargo' : random < 70 ? 'Oil' : random < 80 ? 'Coal' : random < 90 ? 'Minerals' : 'Chemicals';
    }

    generateShipMetadata(type) {
        const config = shipTypeConfigs[type];
        const commodity = type === 'Cargo' ? generalCargoTypes[Math.floor(Math.random() * generalCargoTypes.length)] : null;
        const countryCode = portCountries[this.currentPort] || 'US';
        const FlagIcon = flagComponents[countryCode] || flagComponents['US'];
        return {
            type,
            length: getRandomInRange(config.lengthRange),
            depth: getRandomInRange(config.depthRange),
            cargoCapacity: getRandomInRange(config.cargoCapacityRange),
            penaltyRate: config.penaltyRate,
            businessValue: getRandomInRange(config.businessValueRange),
            commodity,
            cargoValue: getRandomInRange(config.businessValueRange),
            source: this.currentPort,
            destination: this.destinationPort,
            country: countryCode,
            flag: FlagIcon,
        };
    }

    setDestination(newPort) {
        if (!newPort || !this.portCoordinates[newPort]) {
            console.error(`Invalid destination: ${newPort}`);
            return;
        }

        if (this.currentPort === newPort) {
            console.warn(`Ship ${this.id} is already at ${newPort}. No movement needed.`);
            this.isDocked = true;
            return;
        }

        this.metadata.source = this.currentPort;
        this.destinationPort = newPort;
        this.metadata.destination = newPort;
        this.destination = this.portCoordinates[newPort];
        this.isDocked = false;
        this.waitingAtPort = false;
        this.dockingStartTime = null;
        this.startTime = Date.now();
        this.pathIndex = 0;

        const seaRoute = this.getSeaRoute(this.currentPort, newPort);
        if (seaRoute && seaRoute.length > 0) {
            this.path = seaRoute;
        } else {
            console.error(`No valid sea route found for Ship ${this.id} from ${this.currentPort} to ${newPort}`);
            this.isDocked = true;
            this.path = [];
            return;
        }

        const distance = calculateNauticalMiles(this.position[0], this.position[1], this.destination[0], this.destination[1]);
        this.duration = Math.max(3000, (distance / 50) * 5000);
    }

    getSeaRoute(startPort, destinationPort) {
        const routeKey = `${startPort}-${destinationPort}`;
        const reverseRouteKey = `${destinationPort}-${startPort}`;
        if (this.seaRoutes[routeKey]) return this.seaRoutes[routeKey];
        if (this.seaRoutes[reverseRouteKey]) return [...this.seaRoutes[reverseRouteKey]].reverse();
        const startCoords = this.portCoordinates[startPort];
        const destCoords = this.portCoordinates[destinationPort];
        if (startCoords && destCoords) {
            const result = generateGreatCirclePath(startCoords, destCoords, 100);
            return result.path;
        }
        console.error(`Coordinates not found for ports ${startPort} or ${destinationPort}.`);
        return null;
    }

    updatePositionTowards(targetPosition) {
        if (!targetPosition) return;
        const speed = this.speed || 0.0005;
        const [lat1, lon1] = this.position;
        const [lat2, lon2] = targetPosition;
        const deltaLat = lat2 - lat1;
        const deltaLon = lon2 - lon1;
        const distance = Math.sqrt(deltaLat * deltaLat + deltaLon * deltaLon);
        if (distance === 0) return;
        const ratio = speed / distance;
        this.position = [lat1 + deltaLat * ratio, lon1 + deltaLon * ratio];
    }

    updatePosition() {
        try {
            if (this.path && this.path.length > 1 && !this.isDocked) {
                const elapsed = Date.now() - this.startTime;
                const t = Math.min(elapsed / this.duration, 1);
                const totalSegments = this.path.length - 1;
                const progress = t * totalSegments;
                const currentSegment = Math.floor(progress);
                const segmentT = progress - currentSegment;
                if (currentSegment >= totalSegments) {
                    this.position = this.destination;
                    this.isDocked = true;
                    this.waitingAtPort = true;
                    this.dockingStartTime = Date.now();
                    this.currentPort = this.destinationPort;
                    this.destinationPort = null;
                    return;
                }
                const startCoord = this.path[currentSegment];
                const endCoord = this.path[currentSegment + 1];
                if (startCoord && endCoord) {
                    let [lat, lon] = startCoord.map((v, i) => v + (endCoord[i] - v) * segmentT);
                    lon = ((lon + 180) % 360) - 180;
                    this.position = [lat, lon];
                }
            }
        } catch (error) {
            console.error(`Error updating position for Ship ${this.id}:`, error);
        }
    }
}

export default Ship;
