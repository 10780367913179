/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from "react";
import { useMap } from "react-leaflet";
import { Canvas, useFrame } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import * as THREE from "three";

// Fixed container coordinate (update if not visible)
const CONTAINER_COORD = [22.73945494004776, 69.68282835332285];

// Container real-world dimensions (20-foot container)
const CONTAINER_LENGTH_M = 6.1; // length
const CONTAINER_WIDTH_M = 2.44; // width
const CONTAINER_HEIGHT_M = 2.59; // height

const MODEL_PATH = "/assets/container.glb";

function SingleContainer({ map }) {
    const containerRef = useRef();
    const loader = new GLTFLoader();
    const [modelBox, setModelBox] = useState(null);
    const [modelLoaded, setModelLoaded] = useState(false);

    useEffect(() => {
        if (!map) return;

        const loader = new GLTFLoader();
        loader.load(
            MODEL_PATH,
            (gltf) => {
                console.log("Container GLTF Loaded Successfully:", gltf);

                const container = gltf.scene;
                container.rotation.set(
                    THREE.MathUtils.degToRad(90), // Lay flat for top-down view
                    0,
                    0
                );

                container.traverse((child) => {
                    if (child.isMesh) {
                        child.material = new THREE.MeshStandardMaterial({
                            color: "gray",
                            roughness: 0.5,
                            metalness: 0.5,
                        });
                    }
                });

                containerRef.current = container;

                // Compute bounding box for scaling
                const box = new THREE.Box3().setFromObject(container);
                const size = new THREE.Vector3();
                box.getSize(size);
                setModelBox(size);
                setModelLoaded(true);
            },
            undefined,
            (error) => console.error("Error loading Container GLB model:", error)
        );
    }, [map]);

    useFrame(() => {
        if (!map || !modelLoaded || !containerRef.current || !modelBox) return;

        const [lat, lng] = CONTAINER_COORD;

        // Recompute pixelsPerMeter for current map zoom
        const offsetLat = lat + 0.0001;
        const p1 = map.latLngToContainerPoint([lat, lng]);
        const p2 = map.latLngToContainerPoint([offsetLat, lng]);
        const pixelDiff = p2.y - p1.y;
        const meterDiff = map.distance([lat, lng], [offsetLat, lng]);
        if (meterDiff === 0) return; // Avoid division by zero

        const pixelsPerMeter = Math.abs(pixelDiff / meterDiff);
        const desiredPixelLength = CONTAINER_LENGTH_M * pixelsPerMeter;

        // Scale container
        const longestModelDim = Math.max(modelBox.x, modelBox.y, modelBox.z);
        const scaleFactor = desiredPixelLength / longestModelDim;
        containerRef.current.scale.set(scaleFactor, scaleFactor, scaleFactor);

        // Position container
        const containerPoint = map.latLngToContainerPoint([lat, lng]);
        const size = map.getSize();
        const threeX = containerPoint.x;
        const threeY = size.y - containerPoint.y;

        containerRef.current.position.set(threeX, threeY, 0);

        // Debugging logs
        console.log("Three.js Position:", { threeX, threeY });
        console.log("Scale Factor:", scaleFactor);
    });

    return modelLoaded && containerRef.current ? <primitive object={containerRef.current} /> : null;
}

export default function CargoContainerLayer() {
    const map = useMap();

    return (
        <Canvas
            orthographic
            camera={{
                left: 0,
                right: window.innerWidth,
                top: window.innerHeight,
                bottom: 0,
                near: 0.1,
                far: 2000,
                position: [0, 0, 100],
            }}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                pointerEvents: "none",
                width: "100%",
                height: "100%",
                zIndex: 1000,
            }}
        >
            <ambientLight intensity={1} />
            <directionalLight position={[10, 10, 10]} intensity={1} />
            {map && <SingleContainer map={map} />}
        </Canvas>
    );
}
