// src/data/TruckRoutes.js

export const truckRoutes = [
    {
        name: "MundraTruck route 01",
        coordinates: [
            [22.7424559618648, 69.683449600282], [22.7421559273856, 69.6834388930816], [22.7420468133639, 69.6833629909217], [22.7419500568166, 69.6832494822358], [22.7419638601836, 69.6829340730609], [22.7419550587031, 69.682721964912], [22.7418661723911, 69.6826980487568], [22.7417279120452, 69.6826934457626], [22.7415092020153, 69.6826921340219], [22.7412116485455, 69.6826784744622], [22.7406298129302, 69.6826739257419], [22.7400135672288, 69.6826682818347], [22.7394668602954, 69.6826568811934], [22.739172855412, 69.6826868076526], [22.7391483307711, 69.6827643978724], [22.7390541489592, 69.6828710266559], [22.7390331721951, 69.6850852956124], [22.7390198978866, 69.686720804504], [22.7390206966805, 69.688598267485], [22.7390004159193, 69.6904895799213], [22.7389898774653, 69.6918027915599], [22.7389798448694, 69.6939092474344], [22.7389704972022, 69.6959346050194], [22.7389649423622, 69.697423187032], [22.7389592933691, 69.6995714044764], [22.7389534569093, 69.7019491777549], [22.7389489848237, 69.703141066198], [22.7390079876326, 69.7032499066784], [22.7390888569315, 69.7032738805958], [22.7391834157093, 69.7032630630115], [22.7405164566365, 69.7032828138452], [22.7406989929169, 69.7032886597493], [22.7408441383011, 69.703249747988], [22.7409907316846, 69.7030993212206], [22.7411022065905, 69.703005566512], [22.7411509661852, 69.7029091258002], [22.7412436187186, 69.7026852384864], [22.741328198769, 69.7025617586659], [22.7414389609965, 69.7025022209772], [22.7415631796177, 69.702492425272], [22.7418050800206, 69.7024965924535], [22.7420709969204, 69.7024885432708], [22.7424367636073, 69.7025037183894]
            // Add remaining coordinates for the route
        ],
    },
    {
        name: "MundraTruck route 02",
        coordinates: [
            [22.7424223795277, 69.7023116283132], [22.74209245482, 69.7023204135072], [22.7420990120426, 69.7020116181322], [22.7421284622987, 69.7019491129327], [22.7421367611493, 69.7018219798327], [22.7421361728463, 69.7015936640184], [22.7421387156951, 69.7009685733269], [22.7421455507578, 69.7004392834315], [22.7422606503944, 69.7002848253082], [22.7423949723649, 69.700177616748], [22.7424124379709, 69.6993727950186], [22.742408318763, 69.6985169262361], [22.74236384618, 69.6979351917114], [22.7423482001546, 69.6977605452185], [22.7421594613598, 69.6976716026202], [22.7420846436761, 69.6975996723812], [22.7420902474743, 69.6969232176881], [22.7421214178062, 69.6946905158645], [22.7421497279127, 69.6918793699383], [22.7421609361969, 69.6891723114156], [22.7421929150815, 69.6885609349276], [22.7422425617743, 69.6873572305858], [22.7422520659089, 69.685948817022], [22.7421482818584, 69.6857928283232], [22.7419145933248, 69.6857740993559], [22.74181943194, 69.6857398555304], [22.7418250664583, 69.6838727032296], [22.741888676031, 69.6836716282813], [22.7420909202001, 69.6836313212381], [22.742443243916, 69.6836171966495]
            // Add remaining coordinates for the route
        ],
    },
    {
        name: "MundraTruck route 03",
        coordinates: [
            [22.7424549725072, 69.683460008214], [22.7421457847575, 69.6834611336623], [22.7420198428327, 69.6833699566406], [22.741935156609, 69.6832392656781], [22.7419385276953, 69.6827463582459], [22.7417997324363, 69.6827252848869], [22.7415636306925, 69.6827124341474], [22.7412464626018, 69.682737358629], [22.7412412515254, 69.683859584094], [22.7412247941617, 69.6856577138133], [22.7413586610212, 69.6856890281392], [22.741790947288, 69.6856930911038], [22.7418058705308, 69.6838684464248], [22.7418804614459, 69.6836436055429], [22.7424411586967, 69.6836166120939], [22.7424549725072, 69.683460008214]
            // Add remaining coordinates for the route
        ],
    },
];


