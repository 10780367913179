/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from "react";
import { useMap } from "react-leaflet";
import { Canvas, useFrame } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import * as THREE from "three";

// Track endpoints
const startLatLng = { lat: 22.73867641775902, lng: 69.68286078781084 };
const endLatLng = { lat: 22.73857553826172, lng: 69.70302367579234 };

// Desired real-world dimensions of crane
const CRANE_LENGTH_M = 30; // meters (long dimension)
const CRANE_WIDTH_M = 15;   // meters
const CRANE_HEIGHT_M = 20;  // meters (assume height is the vertical dimension)

// Three cranes with different colors and speeds
const cranesConfig = [
    { color: "black", speed: 0.000020 },
    { color: "blue", speed: 0.000030 },
    { color: "purple", speed: 0.000010 }
];

const MODEL_PATH = "/assets/cargoCrane.glb";

// Minimum distance (in meters) to maintain between cranes
const MIN_CRANE_DISTANCE = 20;

function Crane({ map, color, speed, index, positionsRef }) {
    const craneRef = useRef();
    const loader = new GLTFLoader();
    const [modelBox, setModelBox] = useState(null); // Store bounding box after load
    const [t, setT] = useState(index * 0.1); // start slightly offset
    const [baseSpeed, setBaseSpeed] = useState(speed);

    useEffect(() => {
        loader.load(
            MODEL_PATH,
            (gltf) => {
                console.log("GLTF Loaded Successfully:", gltf);
                const crane = gltf.scene;

                // Rotate the crane so its length aligns with track direction if needed
                crane.rotation.set(
                    THREE.MathUtils.degToRad(-90), // Rotate 90� anticlockwise along Y
                    THREE.MathUtils.degToRad(90),  // Rotate 90� clockwise along Z
                    0
                );

                // Set material color
                crane.traverse((child) => {
                    if (child.isMesh) {
                        child.material = new THREE.MeshStandardMaterial({
                            color: color,
                            roughness: 0.5,
                            metalness: 0.5,
                        });
                    }
                });

                craneRef.current.add(crane);

                // Compute bounding box to know model size
                const box = new THREE.Box3().setFromObject(crane);
                const size = new THREE.Vector3();
                box.getSize(size);
                setModelBox(size);

                console.log("Crane added to the scene with color:", color);
            },
            undefined,
            (error) => console.error("Error loading GLB model:", error)
        );
    }, [color]);

    useFrame(({ camera }) => {
        if (!map || !craneRef.current || !modelBox) return;

        // Update camera to match map size
        const size = map.getSize();
        camera.left = 0;
        camera.right = size.x;
        camera.bottom = 0;
        camera.top = size.y;
        camera.updateProjectionMatrix();

        // Position along track
        // t in [0,1], interpolate lat/lng
        const lat = startLatLng.lat + t * (endLatLng.lat - startLatLng.lat);
        const lng = startLatLng.lng + t * (endLatLng.lng - startLatLng.lng);

        // Store positions for collision avoidance
        positionsRef.current[index] = { lat, lng };

        // Collision avoidance: if there's a crane ahead that's too close, slow down or stop
        for (let i = 0; i < positionsRef.current.length; i++) {
            if (i === index) continue;
            const other = positionsRef.current[i];
            if (!other) continue;
            const dist = map.distance([lat, lng], [other.lat, other.lng]);
            // If another crane is ahead on the track and too close, slow down
            // Determine if it's ahead by comparing t-values
            if ((i < index && dist < MIN_CRANE_DISTANCE && t > (index * 0.1)) ||
                (i > index && dist < MIN_CRANE_DISTANCE)) {
                // Slow down drastically
                setBaseSpeed(speed * 0.1);
                break;
            } else {
                setBaseSpeed(speed);
            }
        }

        // Update t
        setT((prevT) => Math.min(prevT + baseSpeed, 1.0));

        // Convert lat/lng to container point
        const containerPoint = map.latLngToContainerPoint([lat, lng]);

        // Compute pixels per meter for scaling
        // Use a small offset in latitude to measure
        const offsetLat = lat + 0.0001;
        const p1 = map.latLngToContainerPoint([lat, lng]);
        const p2 = map.latLngToContainerPoint([offsetLat, lng]);
        const pixelDiff = p2.y - p1.y;
        const meterDiff = map.distance([lat, lng], [offsetLat, lng]);
        const pixelsPerMeter = Math.abs(pixelDiff / meterDiff);

        // Desired pixel length for the crane = CRANE_LENGTH_M * pixelsPerMeter
        // Find the longest dimension in model units (assume length is along x)
        const longestModelDim = Math.max(modelBox.x, modelBox.y, modelBox.z);
        const desiredPixelLength = CRANE_LENGTH_M * pixelsPerMeter;
        const scaleFactor = desiredPixelLength / longestModelDim;

        craneRef.current.scale.set(scaleFactor, scaleFactor, scaleFactor);

        // Convert from Leaflet top-left origin to bottom-left origin in Three.js:
        // We currently have top-left = (0,0), y increasing downward for containerPoint.
        // Our camera has bottom=0, top=height, y increases upwards.
        // To convert: threeY = height - containerPoint.y
        const threeX = containerPoint.x;
        const threeY = size.y - containerPoint.y;

        craneRef.current.position.set(threeX, threeY, 0);
    });

    return <group ref={craneRef} />;
}

function CraneModelLayer() {
    const map = useMap();
    const positionsRef = useRef([]); // Store crane positions for collision avoidance

    return (
        <Canvas
            orthographic
            camera={{
                left: 0,
                right: window.innerWidth,
                top: window.innerHeight,
                bottom: 0,
                near: 0.1,
                far: 2000,
                position: [0, 0, 100],
            }}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                pointerEvents: "none",
                width: "100%",
                height: "100%",
                zIndex: 650,
            }}
        >
            <ambientLight intensity={1} />
            <directionalLight position={[10, 10, 10]} intensity={1} />

            {map && cranesConfig.map((cfg, i) => (
                <Crane
                    key={i}
                    map={map}
                    color={cfg.color}
                    speed={cfg.speed}
                    index={i}
                    positionsRef={positionsRef}
                />
            ))}
        </Canvas>
    );
}

export default CraneModelLayer;
