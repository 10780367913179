/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Marker, Tooltip, useMap } from 'react-leaflet';
import L from 'leaflet';
import shipIconImage from '../assets/ship-icon.png';

const ShipsLayer = ({ ships, onAssignBerth, highlightedShipId }) => {
    const map = useMap();
    const zoom = map.getZoom();

    const getIconSize = (z) => {
        if (z >= 18) return [20, 20];
        if (z < 10) return [40, 40];
        const size = 40 - (z - 10) * (20 / 8);
        return [size, size];
    };

    const iconSize = getIconSize(zoom);
    const posTolerance = 0.00005;

    const groupShips = (arr) => {
        const used = new Set(), groups = [];
        for (let i = 0; i < arr.length; i++) {
            if (used.has(i)) continue;
            const g = [arr[i]]; used.add(i);
            for (let j = i + 1; j < arr.length; j++) {
                if (!used.has(j) && dist(arr[i].position, arr[j].position) < posTolerance) { g.push(arr[j]); used.add(j); }
            }
            groups.push(g);
        }
        return groups;
    };

    const dist = (a, b) => {
        const dLat = b[0] - a[0], dLon = b[1] - a[1];
        return Math.sqrt(dLat * dLat + dLon * dLon);
    };

    const getDir = (ship) => {
        if (!ship.destination) return null;
        const dLat = ship.destination[0] - ship.position[0], dLon = ship.destination[1] - ship.position[1];
        const len = Math.sqrt(dLat * dLat + dLon * dLon);
        return len > 0 ? [dLat / len, dLon / len] : null;
    };

    const spread = (group) => {
        if (group.length === 1) return group.map(s => ({ ...s, adjustedPosition: s.position }));
        const waiting = group.every(s => s.waitingAtPort || s.isDocked);
        const pxSize = iconSize[0];
        const radiusDeg = (pxSize * 2) * 0.000005;
        const c = group[0].position;
        if (waiting) return circle(group, c, radiusDeg);
        const dir = getDir(group[0]);
        if (!dir) return circle(group, c, radiusDeg);
        return line(group, c, dir, radiusDeg);
    };

    const circle = (group, c, r) => {
        return group.map((s, i) => {
            const angle = 2 * Math.PI * i / group.length;
            return { ...s, adjustedPosition: [c[0] + r * Math.cos(angle), c[1] + r * Math.sin(angle) / Math.cos(c[0] * Math.PI / 180)] };
        });
    };

    const line = (group, c, dir, spacing) => {
        const mid = Math.floor(group.length / 2);
        return group.map((s, i) => {
            const off = (i - mid) * spacing;
            return { ...s, adjustedPosition: [c[0] + dir[0] * off, c[1] + (dir[1] * off) / Math.cos(c[0] * Math.PI / 180)] };
        });
    };

    const processedShips = useMemo(() => {
        const gr = groupShips(ships);
        return gr.flatMap(g => spread(g));
    }, [ships, zoom]);

    const handleClick = (ship, e) => {
        if (ship.waitingAtPort) onAssignBerth(ship, e.latlng);
    };

    const shipIcon = useMemo(() => new L.Icon({ iconUrl: shipIconImage, iconSize }), [iconSize]);

    return (
        <>
            {processedShips.map((ship) => {
                const isH = ship.id === highlightedShipId;
                return (
                    <Marker key={ship.id} position={ship.adjustedPosition || ship.position} icon={shipIcon} className={isH ? 'ship-marker-highlighted' : ''}
                        eventHandlers={{ click: (e) => handleClick(ship, e) }}>
                        <Tooltip direction="top" offset={[0, -20]} opacity={1}>
                            <div>
                                <p><strong>ID:</strong> {ship.id}</p>
                                <p><strong>Type:</strong> {ship.metadata.type}</p>
                                <p><strong>Cargo:</strong> {ship.metadata.commodity || ship.metadata.type}</p>
                                <p><strong>Value:</strong> ${ship.metadata.cargoValue}</p>
                                <p><strong>Status:</strong>
                                    {ship.isDocked ? 'Docked' : ship.isMovingToBerth ? 'Moving to Berth' : ship.waitingAtPort ? 'Waiting at Port' : 'On Move'}
                                </p>
                            </div>
                        </Tooltip>
                    </Marker>
                );
            })}
        </>
    );
};

export default ShipsLayer;
